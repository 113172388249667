@use '@angular/material' as mat;
@mixin select-template-theme($theme) {
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  ul.template {
    background-color: mat.get-color-from-palette($background, 'card');
    z-index: 1;
  }

  li.template-container {
    &:nth-child(odd) {
      background-color: rgba(249, 249, 249, 1);
    }
    &:hover {
      background-color: rgba(234, 234, 234, 1);
    }

    .text {
      color: mat.get-color-from-palette($foreground, 'lighten-text');
    }
  }

  li.template-container-selected {
    background-color: rgba(234, 234, 234, 1);

    .text {
      color: mat.get-color-from-palette($foreground, 'lighten-text');
    }
  }
}
