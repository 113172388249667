.flag {
  background-image: url(https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/flags_sprite_2x.png);
  background-size: 100% auto;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.54));
  height: 14px;
  width: 24px;

  &.KY {
    background-position: 0 0;
  }

  &.AC {
    background-position: 0 -14px;
  }

  &.AE {
    background-position: 0 -28px;
  }

  &.AF {
    background-position: 0 -42px;
  }

  &.AG {
    background-position: 0 -56px;
  }

  &.AI {
    background-position: 0 -70px;
  }

  &.AL {
    background-position: 0 -84px;
  }

  &.AM {
    background-position: 0 -98px;
  }

  &.AO {
    background-position: 0 -112px;
  }

  &.AQ {
    background-position: 0 -126px;
  }

  &.AR {
    background-position: 0 -140px;
  }

  &.AS {
    background-position: 0 -154px;
  }

  &.AT {
    background-position: 0 -168px;
  }

  &.AU {
    background-position: 0 -182px;
  }

  &.AW {
    background-position: 0 -196px;
  }

  &.AX {
    background-position: 0 -210px;
  }

  &.AZ {
    background-position: 0 -224px;
  }

  &.BA {
    background-position: 0 -238px;
  }

  &.BB {
    background-position: 0 -252px;
  }

  &.BD {
    background-position: 0 -266px;
  }

  &.BE {
    background-position: 0 -280px;
  }

  &.BF {
    background-position: 0 -294px;
  }

  &.BG {
    background-position: 0 -308px;
  }

  &.BH {
    background-position: 0 -322px;
  }

  &.BI {
    background-position: 0 -336px;
  }

  &.BJ {
    background-position: 0 -350px;
  }

  &.BL {
    background-position: 0 -364px;
  }

  &.BM {
    background-position: 0 -378px;
  }

  &.BN {
    background-position: 0 -392px;
  }

  &.BO {
    background-position: 0 -406px;
  }

  &.BQ {
    background-position: 0 -420px;
  }

  &.BR {
    background-position: 0 -434px;
  }

  &.BS {
    background-position: 0 -448px;
  }

  &.BT {
    background-position: 0 -462px;
  }

  &.BV {
    background-position: 0 -476px;
  }

  &.BW {
    background-position: 0 -490px;
  }

  &.BY {
    background-position: 0 -504px;
  }

  &.BZ {
    background-position: 0 -518px;
  }

  &.CA {
    background-position: 0 -532px;
  }

  &.CC {
    background-position: 0 -546px;
  }

  &.CD {
    background-position: 0 -560px;
  }

  &.CF {
    background-position: 0 -574px;
  }

  &.CG {
    background-position: 0 -588px;
  }

  &.CH {
    background-position: 0 -602px;
  }

  &.CI {
    background-position: 0 -616px;
  }

  &.CK {
    background-position: 0 -630px;
  }

  &.CL {
    background-position: 0 -644px;
  }

  &.CM {
    background-position: 0 -658px;
  }

  &.CN {
    background-position: 0 -672px;
  }

  &.CO {
    background-position: 0 -686px;
  }

  &.CP {
    background-position: 0 -700px;
  }

  &.CR {
    background-position: 0 -714px;
  }

  &.CU {
    background-position: 0 -728px;
  }

  &.CV {
    background-position: 0 -742px;
  }

  &.CW {
    background-position: 0 -756px;
  }

  &.CX {
    background-position: 0 -770px;
  }

  &.CY {
    background-position: 0 -784px;
  }

  &.CZ {
    background-position: 0 -798px;
  }

  &.DE {
    background-position: 0 -812px;
  }

  &.DG {
    background-position: 0 -826px;
  }

  &.DJ {
    background-position: 0 -840px;
  }

  &.DK {
    background-position: 0 -854px;
  }

  &.DM {
    background-position: 0 -868px;
  }

  &.DO {
    background-position: 0 -882px;
  }

  &.DZ {
    background-position: 0 -896px;
  }

  &.EA {
    background-position: 0 -910px;
  }

  &.EC {
    background-position: 0 -924px;
  }

  &.EE {
    background-position: 0 -938px;
  }

  &.EG {
    background-position: 0 -952px;
  }

  &.EH {
    background-position: 0 -966px;
  }

  &.ER {
    background-position: 0 -980px;
  }

  &.ES {
    background-position: 0 -994px;
  }

  &.ET {
    background-position: 0 -1008px;
  }

  &.EU {
    background-position: 0 -1022px;
  }

  &.FI {
    background-position: 0 -1036px;
  }

  &.FJ {
    background-position: 0 -1050px;
  }

  &.FK {
    background-position: 0 -1064px;
  }

  &.FM {
    background-position: 0 -1078px;
  }

  &.FO {
    background-position: 0 -1092px;
  }

  &.FR {
    background-position: 0 -1106px;
  }

  &.GA {
    background-position: 0 -1120px;
  }

  &.GB {
    background-position: 0 -1134px;
  }

  &.GD {
    background-position: 0 -1148px;
  }

  &.GE {
    background-position: 0 -1162px;
  }

  &.GF {
    background-position: 0 -1176px;
  }

  &.GG {
    background-position: 0 -1190px;
  }

  &.GH {
    background-position: 0 -1204px;
  }

  &.GI {
    background-position: 0 -1218px;
  }

  &.GL {
    background-position: 0 -1232px;
  }

  &.GM {
    background-position: 0 -1246px;
  }

  &.GN {
    background-position: 0 -1260px;
  }

  &.GP {
    background-position: 0 -1274px;
  }

  &.GQ {
    background-position: 0 -1288px;
  }

  &.GR {
    background-position: 0 -1302px;
  }

  &.GS {
    background-position: 0 -1316px;
  }

  &.GT {
    background-position: 0 -1330px;
  }

  &.GU {
    background-position: 0 -1344px;
  }

  &.GW {
    background-position: 0 -1358px;
  }

  &.GY {
    background-position: 0 -1372px;
  }

  &.HK {
    background-position: 0 -1386px;
  }

  &.HM {
    background-position: 0 -1400px;
  }

  &.HN {
    background-position: 0 -1414px;
  }

  &.HR {
    background-position: 0 -1428px;
  }

  &.HT {
    background-position: 0 -1442px;
  }

  &.HU {
    background-position: 0 -1456px;
  }

  &.IC {
    background-position: 0 -1470px;
  }

  &.ID {
    background-position: 0 -1484px;
  }

  &.IE {
    background-position: 0 -1498px;
  }

  &.IL {
    background-position: 0 -1512px;
  }

  &.IM {
    background-position: 0 -1526px;
  }

  &.IN {
    background-position: 0 -1540px;
  }

  &.IO {
    background-position: 0 -1554px;
  }

  &.IQ {
    background-position: 0 -1568px;
  }

  &.IR {
    background-position: 0 -1582px;
  }

  &.IS {
    background-position: 0 -1596px;
  }

  &.IT {
    background-position: 0 -1610px;
  }

  &.JE {
    background-position: 0 -1624px;
  }

  &.JM {
    background-position: 0 -1638px;
  }

  &.JO {
    background-position: 0 -1652px;
  }

  &.JP {
    background-position: 0 -1666px;
  }

  &.KE {
    background-position: 0 -1680px;
  }

  &.KG {
    background-position: 0 -1694px;
  }

  &.KH {
    background-position: 0 -1708px;
  }

  &.KI {
    background-position: 0 -1722px;
  }

  &.KM {
    background-position: 0 -1736px;
  }

  &.KN {
    background-position: 0 -1750px;
  }

  &.KP {
    background-position: 0 -1764px;
  }

  &.KR {
    background-position: 0 -1778px;
  }

  &.KW {
    background-position: 0 -1792px;
  }

  &.AD {
    background-position: 0 -1806px;
  }

  &.KZ {
    background-position: 0 -1820px;
  }

  &.LA {
    background-position: 0 -1834px;
  }

  &.LB {
    background-position: 0 -1848px;
  }

  &.LC {
    background-position: 0 -1862px;
  }

  &.LI {
    background-position: 0 -1876px;
  }

  &.LK {
    background-position: 0 -1890px;
  }

  &.LR {
    background-position: 0 -1904px;
  }

  &.LS {
    background-position: 0 -1918px;
  }

  &.LT {
    background-position: 0 -1932px;
  }

  &.LU {
    background-position: 0 -1946px;
  }

  &.LV {
    background-position: 0 -1960px;
  }

  &.LY {
    background-position: 0 -1974px;
  }

  &.MA {
    background-position: 0 -1988px;
  }

  &.MC {
    background-position: 0 -2002px;
  }

  &.MD {
    background-position: 0 -2016px;
  }

  &.ME {
    background-position: 0 -2030px;
  }

  &.MF {
    background-position: 0 -2044px;
  }

  &.MG {
    background-position: 0 -2058px;
  }

  &.MH {
    background-position: 0 -2072px;
  }

  &.MK {
    background-position: 0 -2086px;
  }

  &.ML {
    background-position: 0 -2100px;
  }

  &.MM {
    background-position: 0 -2114px;
  }

  &.MN {
    background-position: 0 -2128px;
  }

  &.MO {
    background-position: 0 -2142px;
  }

  &.MP {
    background-position: 0 -2156px;
  }

  &.MQ {
    background-position: 0 -2170px;
  }

  &.MR {
    background-position: 0 -2184px;
  }

  &.MS {
    background-position: 0 -2198px;
  }

  &.MT {
    background-position: 0 -2212px;
  }

  &.MU {
    background-position: 0 -2226px;
  }

  &.MV {
    background-position: 0 -2240px;
  }

  &.MW {
    background-position: 0 -2254px;
  }

  &.MX {
    background-position: 0 -2268px;
  }

  &.MY {
    background-position: 0 -2282px;
  }

  &.MZ {
    background-position: 0 -2296px;
  }

  &.NA {
    background-position: 0 -2310px;
  }

  &.NC {
    background-position: 0 -2324px;
  }

  &.NE {
    background-position: 0 -2338px;
  }

  &.NF {
    background-position: 0 -2352px;
  }

  &.NG {
    background-position: 0 -2366px;
  }

  &.NI {
    background-position: 0 -2380px;
  }

  &.NL {
    background-position: 0 -2394px;
  }

  &.NO {
    background-position: 0 -2408px;
  }

  &.NP {
    background-position: 0 -2422px;
  }

  &.NR {
    background-position: 0 -2436px;
  }

  &.NU {
    background-position: 0 -2450px;
  }

  &.NZ {
    background-position: 0 -2464px;
  }

  &.OM {
    background-position: 0 -2478px;
  }

  &.PA {
    background-position: 0 -2492px;
  }

  &.PE {
    background-position: 0 -2506px;
  }

  &.PF {
    background-position: 0 -2520px;
  }

  &.PG {
    background-position: 0 -2534px;
  }

  &.PH {
    background-position: 0 -2548px;
  }

  &.PK {
    background-position: 0 -2562px;
  }

  &.PL {
    background-position: 0 -2576px;
  }

  &.PM {
    background-position: 0 -2590px;
  }

  &.PN {
    background-position: 0 -2604px;
  }

  &.PR {
    background-position: 0 -2618px;
  }

  &.PS {
    background-position: 0 -2632px;
  }

  &.PT {
    background-position: 0 -2646px;
  }

  &.PW {
    background-position: 0 -2660px;
  }

  &.PY {
    background-position: 0 -2674px;
  }

  &.QA {
    background-position: 0 -2688px;
  }

  &.RE {
    background-position: 0 -2702px;
  }

  &.RO {
    background-position: 0 -2716px;
  }

  &.RS {
    background-position: 0 -2730px;
  }

  &.RU {
    background-position: 0 -2744px;
  }

  &.RW {
    background-position: 0 -2758px;
  }

  &.SA {
    background-position: 0 -2772px;
  }

  &.SB {
    background-position: 0 -2786px;
  }

  &.SC {
    background-position: 0 -2800px;
  }

  &.SD {
    background-position: 0 -2814px;
  }

  &.SE {
    background-position: 0 -2828px;
  }

  &.SG {
    background-position: 0 -2842px;
  }

  &.SH {
    background-position: 0 -2856px;
  }

  &.SI {
    background-position: 0 -2870px;
  }

  &.SJ {
    background-position: 0 -2884px;
  }

  &.SK {
    background-position: 0 -2898px;
  }

  &.SL {
    background-position: 0 -2912px;
  }

  &.SM {
    background-position: 0 -2926px;
  }

  &.SN {
    background-position: 0 -2940px;
  }

  &.SO {
    background-position: 0 -2954px;
  }

  &.SR {
    background-position: 0 -2968px;
  }

  &.SS {
    background-position: 0 -2982px;
  }

  &.ST {
    background-position: 0 -2996px;
  }

  &.SV {
    background-position: 0 -3010px;
  }

  &.SX {
    background-position: 0 -3024px;
  }

  &.SY {
    background-position: 0 -3038px;
  }

  &.SZ {
    background-position: 0 -3052px;
  }

  &.TA {
    background-position: 0 -3066px;
  }

  &.TC {
    background-position: 0 -3080px;
  }

  &.TD {
    background-position: 0 -3094px;
  }

  &.TF {
    background-position: 0 -3108px;
  }

  &.TG {
    background-position: 0 -3122px;
  }

  &.TH {
    background-position: 0 -3136px;
  }

  &.TJ {
    background-position: 0 -3150px;
  }

  &.TK {
    background-position: 0 -3164px;
  }

  &.TL {
    background-position: 0 -3178px;
  }

  &.TM {
    background-position: 0 -3192px;
  }

  &.TN {
    background-position: 0 -3206px;
  }

  &.TO {
    background-position: 0 -3220px;
  }

  &.TR {
    background-position: 0 -3234px;
  }

  &.TT {
    background-position: 0 -3248px;
  }

  &.TV {
    background-position: 0 -3262px;
  }

  &.TW {
    background-position: 0 -3276px;
  }

  &.TZ {
    background-position: 0 -3290px;
  }

  &.UA {
    background-position: 0 -3304px;
  }

  &.UG {
    background-position: 0 -3318px;
  }

  &.UM {
    background-position: 0 -3332px;
  }

  &.UN {
    background-position: 0 -3346px;
  }

  &.US {
    background-position: 0 -3360px;
  }

  &.UY {
    background-position: 0 -3374px;
  }

  &.UZ {
    background-position: 0 -3388px;
  }

  &.VA {
    background-position: 0 -3402px;
  }

  &.VC {
    background-position: 0 -3416px;
  }

  &.VE {
    background-position: 0 -3430px;
  }

  &.VG {
    background-position: 0 -3444px;
  }

  &.VI {
    background-position: 0 -3458px;
  }

  &.VN {
    background-position: 0 -3472px;
  }

  &.VU {
    background-position: 0 -3486px;
  }

  &.WF {
    background-position: 0 -3500px;
  }

  &.WS {
    background-position: 0 -3514px;
  }

  &.XK {
    background-position: 0 -3528px;
  }

  &.YE {
    background-position: 0 -3542px;
  }

  &.YT {
    background-position: 0 -3556px;
  }

  &.ZA {
    background-position: 0 -3570px;
  }

  &.ZM {
    background-position: 0 -3584px;
  }

  &.ZW {
    background-position: 0 -3598px;
  }
}
