@use '@angular/material' as mat;
@mixin placeholder-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  @keyframes colorfade {
    from {
      background-color: mat.get-color-from-palette($background, 'background');
    }
    50% {
      background-color: mat.get-color-from-palette($foreground, 'disabled');
    }
    to {
      background-color: mat.get-color-from-palette($background, 'background');
    }
  }

  app-placeholder {
    animation: colorfade 3s infinite;
  }
}
