.chart-color-1 {
  color: $L500;
}
.chart-color-2 {
  color: $A600;
}
.chart-color-3 {
  color: $N500;
}

.chart-background-1 {
  background-color: $L500;
  color: $L50;
}
.chart-background-2 {
  background-color: $A600;
  color: $L50;
}
.chart-background-3 {
  background-color: $N500;
  color: $L50;
}
