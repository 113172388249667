@mixin big-number-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  .container {
    .info-icon {
      color: #c0c0c0;
    }

    .title {
      color: #414141;
    }

    .percentage {
      &.positive {
        color: #2d6c10;
        background-color: #afe26d;
      }
      &.negative {
        color: #a12f25;
        background-color: #fdb9b3;
      }
    }

    .current-value {
      color: #414141;
    }

    .old-value {
      color: #c0c0c0;
    }
  }
}
