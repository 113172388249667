@use '@angular/material' as mat;
@import 'ht-variables';
@mixin date-range-selector-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  .date-range-selector label {
    color: mat.get-color-from-palette($foreground, 'icon');
  }
}

:host {
  display: contents;
}

.date-range-selector {
  .mat-form-field {
    max-width: 152px;
    margin: 2px $spacing-xs;
  }
}
