@use '@angular/material' as mat;
@mixin contact-info-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');

  .contact-info-container {
    background-color: mat.get-color-from-palette($background, 'card');

    .info-header .contact-info-title {
      color: mat.get-color-from-palette($foreground, 'base');
    }

    .user-detail .user-detail-title {
      color: mat.get-color-from-palette($foreground, 'base');
    }

    .info-section {
      mat-icon {
        color: mat.get-color-from-palette($foreground, 'hint-text');
      }

      .info-section .additional-info {
        textarea {
          border-bottom-color: mat.get-color-from-palette(
            $foreground,
            'secondary-text'
          );
        }

        .limit-char {
          color: mat.get-color-from-palette($foreground, 'secondary-text');
        }
      }

      .subsection .section-title {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }
    }

    .media-section {
      .icon-doc + div {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }

      .media-button {
        border-color: mat.get-color-from-palette($background, 'card');

        mat-button-toggle {
          background-color: mat.get-color-from-palette($background, 'hover');
          border-color: mat.get-color-from-palette($background, 'card');
        }

        .mat-button-toggle-checked {
          background-color: mat.get-color-from-palette(
            $background,
            'background'
          );
        }
      }
    }

    .note-section {
      .note {
        background: mat.get-color-from-palette($background, 'notes');

        .note-text {
          background: mat.get-color-from-palette($background, 'notes');
        }
        .created-by {
          p {
            background: mat.get-color-from-palette($background, 'notes');
          }
          b {
            background: mat.get-color-from-palette($background, 'notes');
          }
        }
      }
    }
  }
}
