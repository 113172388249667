@import 'ht-typography.scss';
@import 'ht-variables.scss';

dl {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: $spacing-xxs;
  padding-bottom: $spacing-s;
  margin-bottom: $spacing-sm;
  border-bottom: 1px solid $N100;
  dt {
    font-weight: bold;
  }
  dd {
    margin: 0 0 $spacing-xxs;
  }
}

ht-modal .full-content {
  dt,
  dd {
    padding-left: $spacing-ml;
    padding-right: $spacing-ml;
  }
  dd.full {
    padding-left: 0;
    padding-right: 0;
  }
}
