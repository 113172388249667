@use '@angular/material' as mat;
@mixin realtime-dashboard-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');

  .dashboard-table {
    h4 {
      color: mat.get-color-from-palette($primary, 400);
    }

    $row-background: mat.get-color-from-palette($background, 'card');
    .agent-count {
      background-color: mat.get-color-from-palette($background, 'status-bar');
      border: 1px solid $row-background;

      .indicator {
        border: 2px solid $row-background;
      }
    }

    .data-row {
      background-color: $row-background;
    }

    .header-row {
      background-color: mat.get-color-from-palette($background, 'status-bar');
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .material-table {
      background-color: mat.get-color-from-palette(
        $background,
        'secondary-background'
      );
    }

    .separator {
      border-left: 1px solid
        mat.get-color-from-palette($background, 'secondary-background');
    }
  }
}
