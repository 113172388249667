@use '@angular/material' as mat;
@mixin button-theme($theme) {
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );
  $primary-color: mat.get-color-from-palette($primary);

  .ht-btn {
    outline: none;

    .mat-button-focus-overlay {
      opacity: 0 !important;
    }

    &:disabled {
      border-width: 1px;
      opacity: 0.4;
    }

    &.btn-primary {
      border-color: transparent;
      background-color: mat.get-color-from-palette($primary);
      color: mat.get-contrast-color-from-palette($primary, 500);

      &:focus {
        border-color: mat.get-color-from-palette($foreground, 'text-cta');
      }

      &:focus:not(:disabled),
      &:hover:not(:disabled) {
        background-color: mat.get-color-from-palette($primary, 400);
      }

      &:active:not(:disabled) {
        background-color: lighten($primary-color, 5%);
        border-color: transparent;
      }
    }

    &.btn-secondary {
      border-color: mat.get-color-from-palette($foreground, 'button-border');
      background-color: mat.get-color-from-palette($background, 'dialog');
      color: mat.get-color-from-palette($foreground, 'text-cta');

      &:focus {
        background-color: mat.get-color-from-palette($background, 'background');
        border-color: mat.get-color-from-palette($foreground, 'text-cta');
      }

      &:active {
        background-color: mat.get-color-from-palette($background, 'status-bar');
        border-color: mat.get-color-from-palette($foreground, 'button-border');
      }

      &:hover {
        background-color: mat.get-color-from-palette($background, 'background');
      }

      &:disabled {
        background-color: mat.get-color-from-palette($background, 'background');
      }
    }
  }
}
