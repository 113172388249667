@import 'ht-variables.scss';
@import 'ht-typography.scss';

.ht-tooltip {
  max-width: 342px;
  background: #413f48;
  border-radius: $radius-xs;
  padding: $spacing-xxs $spacing-xs $spacing-xxs $spacing-xs;
  @extend .text-small;
  color: white;
  text-align: left;
}
