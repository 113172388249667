@use '@angular/material' as mat;
@mixin chip-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');

  .ht-chip {
    &.chip-primary {
      background-color: mat.get-color-from-palette($primary);
      color: mat.get-contrast-color-from-palette($primary, 500);
    }

    &.chip-secondary {
      background-color: mat.get-color-from-palette($background, 'status-bar');
      color: mat.get-color-from-palette($foreground, 'lighten-text');
    }
  }
}
