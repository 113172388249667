@use '@angular/material' as mat;
@import 'ht-variables';

@mixin case-chat-message-theme($theme) {
  $accent: map-get($theme, 'accent');
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');
  $warn: map-get($theme, 'warn');

  .case-message {
    &.case-message-agent {
      .message-body .blob {
        color: $N900;
        background: #ffffff;

        a,
        .message-translation-btn {
          color: $L500;

          &:hover {
            color: $L500;
            text-decoration: underline;
          }
        }

        .author {
          color: $N500;
        }

        &:before {
          border-left-color: mat.get-color-from-palette($foreground, 'divider');
        }

        &.not-sent {
          color: $N300;

          .author {
            color: $N300;
          }
        }

        .translated-text {
          color: $N500;
        }
      }
    }

    &.case-message-enduser {
      .message-body .blob {
        &:before {
          border-right-color: mat.get-color-from-palette(
            $foreground,
            'divider'
          );
        }

        a,
        .message-translation-btn {
          color: $L200;

          &:hover {
            color: $L200;
          }
        }

        .author {
          color: mat.get-color-from-palette($foreground, 'success');
        }

        .reply-message {
          background: $N600;
          border-left: 3px solid $L500;
        }

        .translated-text {
          color: $N300;
        }
      }
    }

    &.case-message-info {
      color: mat.get-color-from-palette($foreground, 'secondary-text');

      span {
        color: mat.get-color-from-palette($foreground, 'text-cta');

        &.message-part-description {
          color: #696973;
        }
      }
    }

    &.case-resolved {
      color: mat.get-color-from-palette($foreground, 'lighten-text');

      .pill {
        background-color: mat.get-color-from-palette(
          $foreground,
          'lighten-text'
        );
        color: mat.get-contrast-color-from-palette($primary, 500);
      }
    }

    &.case-note {
      .message-body .blob {
        background-color: mat.get-color-from-palette($background, 'notes');
        color: mat.get-contrast-color-from-palette($accent, 50);

        &:before {
          border-left-color: mat.get-color-from-palette($primary, 500);
        }

        a {
          color: $L500;
          &:hover {
            text-decoration: underline;
          }
        }

        .author {
          color: $N600;
        }
      }
    }

    .bot-avatar {
      color: mat.get-color-from-palette($foreground, 'icons');
    }

    .message-body {
      .blob {
        background-color: $N800;
        color: #ffffff;

        &.message-missed-blob,
        &.message-deleted-blob {
          background-color: mat.get-color-from-palette($warn, 50);

          &:before {
            border-right-color: mat.get-color-from-palette(
              $warn,
              50
            ) !important;
          }
        }

        a {
          color: mat.get-color-from-palette($primary, 800);
          &:hover {
            text-decoration: underline;
          }
        }

        .document {
          .mat-button {
            border: 1px solid mat.get-color-from-palette($primary, 500);
          }
        }

        .reply-message {
          color: #ffffff;
        }
      }

      .footer {
        color: $N500;

        .ack {
          i {
            color: $N500;

            &.read {
              color: $L500;
            }
          }

          &.error {
            color: $R600;

            i {
              color: $R600;
            }
          }
        }

        .date {
          color: mat.get-color-from-palette($foreground, 'secondary-text');
        }
      }
    }
  }
}
