@import 'ht-variables';
@import 'ht-typography';

.ht-tab {
  border-bottom: 1px solid $N100;
  text-decoration: auto;
  display: flex;
  list-style: none;
  padding: 0px $spacing-ml $spacing-xxs $spacing-ml;
  gap: $spacing-sm;

  .ht-tab-link {
    padding-bottom: 5px;
    @extend .text-body-small;
    cursor: pointer;
    &.active {
      @extend .bold;
      border-bottom: 2px solid $L500;
    }
  }
  a {
    color: $N900;
  }
}
