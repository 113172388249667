@use '@angular/material' as mat;
@mixin search-box-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  app-search-box {
    button mat-icon {
      color: mat.get-color-from-palette($foreground, 'icon');
    }

    input {
      border: 1px solid mat.get-color-from-palette($foreground, 'dividers');
      background-color: mat.get-color-from-palette($background, 'input');
      color: mat.get-color-from-palette($foreground, 'text');
      outline: none;

      &:focus {
        border-color: mat.get-color-from-palette($foreground, 'elevation', 0.2);
      }
    }

    .search-icon {
      color: mat.get-color-from-palette($foreground, 'icon');
    }
  }
}
