@import 'ht-variables.scss';
@import 'ht-typography.scss';

.dropdown-menu {
  // do not change position to fix, if the dropdown is in a scrollable area the dropdown will not move with the button
  position: absolute;
  background: #ffffff;
  box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px rgb(0 0 0 / 14%),
    0px 1px 10px rgb(0 0 0 / 12%);
  border-radius: $radius-xs;
  padding: $spacing-xs 0px;
  display: none;
  z-index: 22;
}

.dropdown-menu-item {
  padding: $spacing-xs $spacing-sm;
  display: flex;
  border-radius: 0px;
  color: $N800;
  @extend .text-body-small;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: $L50;
  }
  &.active {
    background-color: $L100;
    font-weight: 400;
  }
}

.menu {
  ht-radiobutton label,
  ht-checkbox label.checkbox-container {
    @extend .dropdown-menu-item;
  }
}
