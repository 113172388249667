@use '@angular/material' as mat;
@import 'ht-variables';
@mixin paginator-theme($theme) {
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );

  app-paginator button.selected {
    color: mat.get-color-from-palette($primary, 500) !important;
  }
}

button {
  min-width: 40px;
  padding: 0 $spacing-xs;
}
