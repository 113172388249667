@use '@angular/material' as mat;
@mixin flat-table-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );

  .flat-table {
    td {
      color: mat.get-color-from-palette($foreground, 'lighten-text');
    }

    th {
      color: mat.get-color-from-palette($foreground, 'text');
      background-color: mat.get-color-from-palette($background, 'card');
    }

    tr {
      background-color: mat.get-color-from-palette($background, 'card');
      &:nth-child(2n + 1) {
        background-color: mat.get-color-from-palette(
          $background,
          'secondary-background'
        );
      }

      & > td:first-child {
        color: mat.get-color-from-palette($foreground, 'text');
      }
      &:hover {
        background-color: mat.get-color-from-palette($background, 'hover');
      }
    }
  }
}
