@use '@angular/material' as mat;
@mixin template-tag-vars-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );
  $primary: map-get($theme, 'primary');

  app-template-tag-variables {
    .variable {
      background-color: mat.get-color-from-palette(
        $foreground,
        'secondary-text'
      );
      color: mat.get-contrast-color-from-palette($primary, 500);

      &.var-set {
        background-color: mat.get-color-from-palette($primary, 500);
      }

      i.mat-icon-grey {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }
      i.mat-icon-blue {
        color: mat.get-color-from-palette($primary, 500);
      }
      input {
        background-color: transparent;
        color: mat.get-contrast-color-from-palette($primary, 500);
        outline: 0;

        &::placeholder {
          color: mat.get-contrast-color-from-palette($primary, 500);
          opacity: 0.5;
        }
      }

      .select-circle {
        background-color: mat.get-contrast-color-from-palette($primary, 500);
      }
    }
  }
}
