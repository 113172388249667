@use '@angular/material' as mat;
@import 'ht-variables';
@mixin tag-theme($theme) {
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );
  $accent: map-get(
    $map: $theme,
    $key: 'accent',
  );

  mat-chip {
    // Styled this way unless they use the basic primary/accent colors
    //  in that case use tag attributes instead
    color: mat.get-contrast-color-from-palette($primary, 500) !important;
    background-color: mat.get-color-from-palette($primary, 500) !important;

    &.excluded {
      color: mat.get-contrast-color-from-palette($accent, 800) !important;
      background-color: mat.get-color-from-palette($accent, 600) !important;
    }
  }
}

:host {
  display: contents;
}

mat-chip {
  border-radius: 2px;
  cursor: pointer;

  font-size: 10px;
  line-height: 12px;

  min-height: 28px;
  padding: $spacing-xxs 7px;
}

.type {
  margin-left: $spacing-xs;
}

.value {
  font-weight: bold;
  text-transform: uppercase;
}
