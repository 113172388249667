@use '@angular/material' as mat;
@mixin filter-menu-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  app-filter-menu .button {
    &.filter-open {
      border-color: mat.get-color-from-palette($foreground, 'elevation', 0.2);
      background-color: mat.get-color-from-palette($background, 'hover');
    }

    button,
    mat-icon {
      color: mat.get-color-from-palette($foreground, 'text');
    }

    span {
      background-color: mat.get-color-from-palette($background, 'background');
      color: mat.get-color-from-palette($foreground, 'text-cta');
    }
  }
}
