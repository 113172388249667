@use '@angular/material' as mat;
@mixin popover-theme($theme) {
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  app-popover > .ht-popover {
    background-color: mat.get-color-from-palette($background, 'dialog');
    color: mat.get-color-from-palette($foreground, 'base');
  }
}
