@use '@angular/material' as mat;
@mixin table-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  app-table {
    color: mat.get-color-from-palette($foreground, 'text');

    thead {
      border-bottom: 2px solid
        mat.get-color-from-palette($foreground, 'divider');
    }
  }
}
