@import 'ht-typography.scss';
@import 'ht-variables.scss';

.ht-chart {
  display: flex;
  gap: $spacing-sm;
  flex-direction: column;
  .ht-chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-sm;

    .ht-chart-header-title {
      display: flex;
      flex-direction: column;
      gap: $spacing-xs;
      color: $N700;
      @extend .text-body-small;
    }
  }
}

.ht-chart-tooltip {
  > span {
    display: flex;
    flex-direction: column;
    gap: $spacing-xxs;
    border-radius: $radius-xs;
    background: white;
    padding: $spacing-sm;
    box-shadow:
      0px 1px 4px rgb(0 0 0 / 10%),
      0px 1px 1px rgb(0 0 0 / 10%);
  }
  b {
    font-weight: normal;
  }
  .ht-chart-tooltip-header {
    font-weight: bold;
  }
  .secondary-data {
    color: $N500;
  }
  &.tooltip-info > span {
    background: $N700;
    border-radius: $radius-xs;
    padding: $spacing-xxs $spacing-xs;
    color: white !important;
    @extend .text-small;
  }
}

.ht-chart-base-tooltip {
  span {
    display: flex;
    flex-direction: column;
    gap: $spacing-xxs;
    border-radius: $radius-xs;
    background: white;
    padding: $spacing-sm;
    box-shadow:
      0px 1px 4px rgb(0 0 0 / 10%),
      0px 1px 1px rgb(0 0 0 / 10%);
  }
  .tooltip-title {
    @extend .text-small;
    @extend .bold;
    &.with-top-margin {
      margin-top: 10px !important;
    }
  }

  .data {
    @extend .text-small;
    display: flex;
    align-items: center;
    gap: $spacing-xs;
    .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .percentage {
      color: $N500;
      margin-left: $spacing-xs;
    }
  }

  &.stacked-bar-tooltip {
    span {
      margin-left: (-$spacing-xs) !important;
      .data {
        margin-left: $spacing-xs;
      }
    }
  }

  &.bar-tooltip {
    span {
      margin-left: (-$spacing-xs) !important;
    }
  }
  &.area-tooltip {
    .square {
      width: 10px;
      height: 10px;
    }

    .diamond {
      width: 9px;
      height: 9px;
      margin-left: 1px;
      transform: rotate(45deg);
    }
  }
  &.pie-tooltip,
  &.line-tooltip {
    span {
      padding: $spacing-xs;
    }
    .data {
      margin-left: $spacing-xs;
    }
  }
}

.highcharts-yaxis-grid.grid-line-color {
  .highcharts-grid-line:nth-child(1) {
    stroke: '#D9D9D9' !important;
  }
  .highcharts-grid-line:nth-child(2) {
    stroke: $P200 !important;
  }

  .highcharts-grid-line:nth-child(3) {
    stroke: $P200 !important;
  }
  .highcharts-grid-line:nth-child(4) {
    stroke: $O300 !important;
  }

  .highcharts-grid-line:nth-child(5) {
    stroke: $G200 !important;
  }

  .highcharts-grid-line:nth-child(6) {
    stroke: $G300 !important;
  }
}

.highcharts-legend-item {
  &.highcharts-pie-series {
    .data {
      @extend .text-small;
      display: flex;
      align-items: center;
      gap: $spacing-xxs;
      min-width: 140px;

      .circle {
        width: 16px;
        height: 16px;
        border-radius: $radius-xs;
      }

      .percentage {
        @extend .bold;
        margin-left: auto;
      }
    }
  }
}
