@use '@angular/material' as mat;
@mixin avatar-theme($theme) {
  //Status colors
  $status-available: #6dcd39;
  $status-away: #f5a623;
  $status-busy: #ec4343;
  $status-offline: #666666;

  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  .avatar .secondary {
    border-color: mat.get-color-from-palette($background, 'raised-button');
    &.status_available {
      background-color: $status-available;
    }
    &.status_away {
      background-color: $status-away;
    }
    &.status_busy {
      background-color: $status-busy;
    }
    &.status_offline {
      background-color: $status-offline;
    }
  }
}
