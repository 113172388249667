@use '@angular/material' as mat;
@mixin button-event-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');

  .button-event {
    .button-title {
      color: mat.get-color-from-palette($primary, 300);
      border-color: mat.get-color-from-palette($background, 'focused-button');
    }
    .click {
      color: mat.get-color-from-palette($foreground, 'lighten-text');
    }
  }
}
