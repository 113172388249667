ul.ngx-pagination {
  padding-inline-start: 0px !important;

  .pagination-next a::after,
  .pagination-next.disabled::after {
    content: '>';
    margin-left: 0.1rem !important;
  }
  .pagination-previous a::before,
  .pagination-previous.disabled::before {
    content: '<';
    margin-right: 0.1rem !important;
  }

  li {
    border: transparent !important;
    border-radius: 4px !important;
    color: white;
    cursor: default;
    @extend .text-body-small;

    a:hover {
      border-radius: 4px !important;
    }
    :active {
      background: $L600 !important;
      color: white;
      border-radius: 4px !important;
    }
    &.current {
      background: $L500 !important;
    }

    &.disabled {
      color: $N300;
    }
  }
}
