@use '@angular/material' as mat;
@mixin campaign-list-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );
  $warn: map-get(
    $map: $theme,
    $key: 'warn',
  );

  .campaign-actions app-popover .send-stats {
    color: mat.get-color-from-palette($foreground, 'success');

    &.warn {
      color: mat.get-color-from-palette($warn, 700);
    }

    &.main {
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}
