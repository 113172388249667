@mixin flex-layout($direction, $justify, $alignment) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $alignment;
}

$grid-columns: 12;

@mixin span-styles($span) {
  grid-column: span #{$span};
}

@mixin col-span-classes {
  @for $i from 2 through $grid-columns {
    .grid-span-#{$i} {
      @include span-styles(#{$i});
    }
  }
}
