@use '@angular/material' as mat;
@mixin button-message-theme($theme) {
  $background: map-get($theme, 'background');
  $primary: map-get($theme, 'primary');

  .button-message {
    .buttons {
      border-color: mat.get-color-from-palette($background, 'focused-button');

      .button {
        border-top-color: mat.get-color-from-palette(
          $background,
          'focused-button'
        );
        color: mat.get-color-from-palette($primary, 300);
      }
    }

    .title {
      background-color: transparent;
    }
  }
}
