@use '@angular/material' as mat;
@mixin case-list-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  $case-list-bg: mat.get-color-from-palette($background, 'background');
  $case-empty-border: mat.get-color-from-palette($foreground, 'divider');

  app-case-list {
    .case-list {
      border-right: 1px solid $case-empty-border;
    }

    .empty-search {
      border: 1px solid $case-empty-border;
      background-color: darken($color: $case-list-bg, $amount: 2%);
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }
}
