@use '@angular/material' as mat;
@import 'ht-variables';

@mixin campaign-schedule-theme($theme) {
  $foreground: map-get($theme, 'foreground');

  .sending-pace-desc {
    color: mat.get-color-from-palette($foreground, 'hint-text');
  }
}
