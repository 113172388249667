// Import library functions for theme creation.
@use '@angular/material' as mat;
@import '../src/app/shared/variables.scss';

@mixin hubtype-shared-theme($theme) {
  @include anchor-theme($theme);
  @include body-theme($theme);
  @include card-theme($theme);
  @include dialog-theme($theme);
  @include filter-menu-panel-theme($theme);
  @include foreground-theme($theme);
  @include header-theme($theme);
  @include list-stripped($theme);
  @include button-theme($theme);
  @include feedback-theme($theme);
}

@mixin anchor-theme($theme) {
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );

  a.plain {
    color: mat.get-color-from-palette($primary, 300);
  }
}

@mixin body-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );

  app-root {
    background-color: mat.get-color-from-palette($background, 'background');
  }

  h1,
  h2,
  h3 {
    color: mat.get-color-from-palette($foreground, 'base');
  }

  .mat-dialog-title {
    color: mat.get-contrast-color-from-palette($primary, 500);
  }
}

@mixin card-theme($theme) {
  $background: map-get($theme, 'background');

  .mat-card {
    border: 1px solid mat.get-color-from-palette($background, 'status-bar');
  }
}

@mixin dialog-theme($theme) {
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );
  $warn: map-get(
    $map: $theme,
    $key: 'warn',
  );

  .mat-dialog-container .dialog-header {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-contrast-color-from-palette($primary, 500);
    &.warn-header {
      background-color: mat.get-color-from-palette($warn, 800);
      color: mat.get-contrast-color-from-palette($warn, 800);
    }
  }

  .mat-dialog-container .dialog-body .warning-text {
    color: mat.get-color-from-palette($warn, 800);
  }
}

@mixin filter-menu-panel-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  .filter-menu {
    .grouped {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .filter-menu-item .mat-checkbox-label {
      color: mat.get-color-from-palette($foreground, 'lighten-text');
    }
  }
}

@mixin foreground-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  .secondary {
    color: mat.get-color-from-palette($foreground, 'secondary-text');
  }

  .hint {
    color: mat.get-color-from-palette($foreground, 'hint-text');
  }
}

@mixin header-theme($theme) {
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );

  .header {
    background-color: mat.get-color-from-palette($primary);

    h2 {
      color: mat.get-contrast-color-from-palette($primary, 500);
      margin: auto $spacing-m auto 0;
    }
    [mat-button] {
      color: mat.get-contrast-color-from-palette($primary, 500);

      &.active {
        background-color: mat.get-contrast-color-from-palette($primary, 800);
        color: mat.get-color-from-palette($primary, 800);
      }
    }
  }
}

@mixin list-stripped($theme) {
  $background: map-get($theme, 'background');
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );

  mat-list.list-striped {
    background-color: mat.get-color-from-palette($background, 'card');
    mat-list-item {
      &:nth-child(2n + 1) {
        background-color: mat.get-color-from-palette(
          $background,
          'secondary-background'
        );
      }

      &:hover {
        background: mat.get-color-from-palette($background, 'hover');
      }

      span.item-edit-btn {
        color: mat.get-color-from-palette($primary);

        &:hover {
          color: mat.get-color-from-palette($primary, 800);
        }
      }

      &.active {
        background: mat.get-color-from-palette($primary);
        .mat-line {
          color: mat.get-contrast-color-from-palette($primary, 500);
        }
      }
    }
  }
}

@mixin button-theme($theme) {
  $background: map-get($theme, 'background');
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );

  button.gray-button {
    box-shadow: 0px 1px 2px mat.get-contrast-color-from-palette($primary, 200);
    background-color: mat.get-contrast-color-from-palette($primary, 600);
  }
}

@mixin feedback-theme($theme) {
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );
  $warn: map-get(
    $map: $theme,
    $key: 'warn',
  );

  .feedback {
    color: mat.get-contrast-color-from-palette($primary, 500) !important;
    background-color: mat.get-color-from-palette($warn, 900);
    &.success {
      color: mat.get-contrast-color-from-palette($primary, 500) !important;
      background-color: $feedback-green !important;
    }
  }

  .text-feedback {
    color: mat.get-color-from-palette($warn, 900) !important;
    &.success {
      color: $feedback-green !important;
    }
  }
}
