@import 'hubtype/ht-hubtype.scss';
@import '../app/shared/variables.scss';

.alert-primary {
  background-color: #e4f0fe;
  padding: $spacing-xs;
  text-align: left;
  border-radius: 4px;
  mat-icon {
    color: $background-logo-blue;
    font-size: 16px;
    height: 16px;
    width: 16px;
  }
}

.mat-button,
.mat-raised-button {
  border-radius: 20px !important;
  .mat-button-ripple {
    border-radius: 20px !important;
  }

  padding: $btn-padding !important;
  font-size: 12px !important;
  text-transform: uppercase;
  line-height: 14.4px !important;
  font-weight: 700 !important;

  box-shadow: none !important;
  border: $btn-border !important;
}
.mat-icon-button {
  border: none !important;
  padding: 0px !important;
  box-shadow: none;
}

.color-scheme-light-blue
  .mat-form-field.mat-form-field-invalid
  .mat-form-field-ripple,
.color-scheme-light-blue
  .mat-form-field.mat-form-field-invalid
  .mat-form-field-ripple.mat-accent {
  background-color: $feedback-red !important;
}

mat-form-field {
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: $darkgray;
  }
}
mat-error,
mat-hint.mat-hint-error {
  color: $feedback-red !important;
  font-size: 12px !important;
}

.header {
  .mat-button {
    font-size: 14px !important;
    border-color: transparent !important;
    font-weight: 500 !important;
  }
}

/*fix datepicker*/
span.easepick-wrapper {
  bottom: 0px !important;
  left: 0px !important;
}
