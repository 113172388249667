@import './ht-variables.scss';
@import './ht-typography.scss';

.ai-list {
  overflow-y: auto;

  .list-item {
    display: flex;
    flex-direction: column;
    padding: $spacing-sm;
    border-bottom: 1px solid $N50;
    cursor: pointer;

    &.no-cursor {
      cursor: auto;
    }
    &:first-child {
      border-top: 1px solid $N50;
    }

    .title {
      @extend .text-body-big;
    }
    .text-small {
      color: $N500;
    }

    .info {
      display: flex;
      flex-flow: row wrap;
      margin-top: 0.5rem;

      .field {
        @extend .text-small;
        @extend .bold;
        color: $N500;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-right: 10px;

        span {
          @extend .text-small;
          color: $N500;
          margin-left: $spacing-xxs;
        }

        a {
          color: $L500;
        }

        .circle {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          margin-left: $spacing-xxs;
        }

        .red {
          background-color: $R600;
        }

        .green {
          background-color: $G600;
        }

        .orange {
          background-color: $O500;
        }
        .gray {
          background-color: $N100;
        }
      }
    }
  }
}
