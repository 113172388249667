@use '@angular/material' as mat;
@mixin whatsapp-settings-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  .channel-detail {
    background-color: mat.get-color-from-palette($background, 'dialog');

    label {
      color: mat.get-color-from-palette($foreground, 'text-cta');
    }

    & > button {
      background-color: transparent !important;
    }
  }
}
