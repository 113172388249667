@use '@angular/material' as mat;
@mixin contacts-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );
  $warn: map-get(
    $map: $theme,
    $key: 'warn',
  );

  .contact {
    .contact-id {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }

  .import-progress {
    border: 1px solid mat.get-color-from-palette($foreground, 'button-border');

    .error {
      color: mat.get-color-from-palette($warn, 600);
    }

    .success {
      color: mat.get-color-from-palette($foreground, 'success');
    }
  }

  .paginator {
    p {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }
  }

  .contacts-meta > div {
    &:last-child {
      color: mat.get-color-from-palette($foreground, 'hint-text');
    }
    &:first-child {
      color: unset;
    }
  }
}
