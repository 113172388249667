@import 'ht-variables.scss';
@import 'ht-nav.scss';
@import 'ht-typography.scss';
@import 'ht-dropdown.scss';
@import 'ht-ai-list.scss';
@import 'ht-paginator.scss';
@import 'ht-form.scss';
@import 'ht-definition-list.scss';
@import 'ht-tab.scss';
@import 'ht-sticky-modal.scss';
@import 'ht-charts.scss';
@import 'ht-tooltip.scss';
@import 'ht-chart-colors.scss';
@import 'ht-flags.scss';

.no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.break-word {
  word-break: break-word;
  white-space: break-spaces;
}

.ht-header {
  border-bottom: solid 1px $N100;
  margin: 0px (-$spacing-ml) $spacing-sm (-$spacing-ml);
  padding: $spacing-s $spacing-ml $spacing-sm $spacing-ml;
}

.ht-header-tabs {
  padding-top: $spacing-s;
}

body {
  overflow: hidden;
}

.popover-content {
  @extend .text-small;
  background-color: black;
  color: white;
  padding: 6px $spacing-sm;
  border-radius: $radius-xs;
  position: absolute;
  margin-left: 6px;
  z-index: 5;
}
