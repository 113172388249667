@use '@angular/material' as mat;
@mixin status-pill-theme($theme) {
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );
  $accent: map-get(
    $map: $theme,
    $key: 'accent',
  );
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );

  .indicator {
    &.blue {
      background-color: mat.get-color-from-palette($primary, 500);
    }
    &.green {
      background-color: mat.get-color-from-palette($foreground, 'success');
    }
    &.grey {
      // Should be moved to a background color
      background-color: mat.get-contrast-color-from-palette($primary, 300);
    }
    &.orange {
      background-color: mat.get-color-from-palette($accent, 800);
    }
  }
}
