@use '@angular/material' as mat;
@mixin inbox-theme($theme) {
  $accent: map-get(
    $map: $theme,
    $key: 'accent',
  );
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );
  $warn: map-get(
    $map: $theme,
    $key: 'warn',
  );

  .case-list {
    h4 {
      color: mat.get-contrast-color-from-palette($primary, 300);
    }

    .feedback {
      color: mat.get-color-from-palette($warn, 900);
      background-color: mat.get-contrast-color-from-palette($primary, 300);
    }

    .attending,
    .waiting,
    .idle {
      .filter-queue {
        background-color: mat.get-contrast-color-from-palette($primary, 200);
        color: mat.get-contrast-color-from-palette($primary, 600);
      }
    }

    .new-conversation {
      .container {
        color: mat.get-contrast-color-from-palette($primary, 300);
      }
      .container:hover {
        color: mat.get-color-from-palette($foreground, 'base');
      }
    }
  }

  .no-queues {
    color: mat.get-contrast-color-from-palette($primary, 500);
    margin: auto;
    padding-top: 15px;

    a {
      color: mat.get-contrast-color-from-palette($primary, 500);
    }
  }
}
