@use '@angular/material' as mat;
@import 'ht-variables';

@import '../../shared/variables.scss';

.text-heading {
  margin-bottom: $spacing-sm;
}
@mixin organization-detail-theme($theme) {
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );
  $warn: map-get(
    $map: $theme,
    $key: 'warn',
  );

  .display-options {
    background-color: mat.get-color-from-palette($background, 'card');
  }
}
