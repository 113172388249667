@import 'ht-variables';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700');

a {
  text-decoration: none;
  text-decoration-color: none;
  cursor: pointer;
}

/*Font-Family*/
.default-font {
  font-family: 'Inter';
}

/*Body*/
.text-body {
  @extend .default-font;
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-sm;
  line-height: 150%;
  margin: 0px;
}

.text-body-big {
  @extend .text-body;
  font-size: $font-size-m;
}
.text-body-small {
  @extend .text-body;
  font-size: $font-size-s;
}
.text-small {
  @extend .text-body;
  font-size: $font-size-xs;
}
.text-small-extra {
  @extend .text-body;
  font-size: $font-size-xxs;
}
.text-mini {
  @extend .text-body;
  font-size: 8px;
}

.text-secondary {
  color: $N500;
}

/*Heading*/
.text-heading {
  @extend .text-body;
  font-weight: 700;
  font-size: $font-size-l;
  line-height: 125%;
  letter-spacing: -0.4px;
}

.text-heading-big {
  @extend .text-heading;
  font-size: $font-size-xxl;
}
.text-heading-medium {
  @extend .text-heading;
  font-size: $font-size-xl;
}

.bold {
  font-weight: 600;
}
