@use '@angular/material' as mat;
@mixin org-display-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  .theme-label {
    color: mat.get-color-from-palette($foreground, 'base');
  }
}

.theme-options {
  display: grid;
  justify-content: space-between;

  grid-template-columns: 20% 20% 20% 20% 20%;
}
