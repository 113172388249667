@use '@angular/material' as mat;
@import 'ht-variables';
@import '../../shared/variables.scss';

@mixin holiday-selector-theme($theme) {
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );
  $warn: map-get(
    $map: $theme,
    $key: 'warn',
  );

  .remove {
    color: mat.get-contrast-color-from-palette($primary, 500);
    background-color: mat.get-color-from-palette($warn, 600);
  }

  input {
    color: mat.get-contrast-color-from-palette($primary, 350);
  }

  .close {
    .material-icons {
      font-size: 18px;
      color: mat.get-contrast-color-from-palette($primary, 400);
    }
  }

  .see-past {
    color: mat.get-contrast-color-from-palette($primary, 50);
    &:hover {
      background-color: mat.get-color-from-palette(
        $background,
        'secondary-background'
      );
    }
  }

  .h-section-title {
    color: mat.get-contrast-color-from-palette($primary, 300);
  }
}

.h-section-title {
  font-size: 22px;
  line-height: 26px;
  margin-bottom: $spacing-xs !important;
}

.holidays-form {
  ::ng-deep .mat-form-field-suffix {
    align-self: flex-end;
    background-color: transparent !important;
    button {
      background-color: transparent !important;
      box-shadow: none;
    }
    .mat-datepicker-toggle-default-icon {
      width: 20px;
      vertical-align: initial !important;
      padding-bottom: 3px;
    }
  }
  .holiday-container {
    align-items: center;
    .date-selectors {
      place-content: space-between;
      width: 430px;
    }
  }
  .remove {
    cursor: pointer;
    height: 42px;
    width: 80px;
    display: flex;
    align-items: center;
    place-content: center;
    text-transform: uppercase;
    margin-left: 10px;
  }
  .close {
    padding-top: 10px;
    margin-left: $spacing-m;

    cursor: pointer;
    .material-icons {
      font-size: 18px;
    }
  }

  .sections-button {
    margin-top: 10px;
    margin-bottom: $spacing-xxl;
    button {
      height: 26px;
    }
    p {
      margin-left: $spacing-s;
      margin-bottom: 0px !important;
      place-self: center;
    }
  }

  .see-past {
    margin-top: $spacing-m;
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    border-radius: 7px;

    text-transform: uppercase;
    font-size: 11px;

    cursor: pointer;
  }
}
