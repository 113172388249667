@mixin create-edit-template-dialog-theme($theme) {
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );

  .dialog-body .template-text {
    outline: none;
  }
}
