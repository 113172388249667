@use '@angular/material' as mat;
@mixin case-chat-header-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $accent: map-get($theme, 'accent');

  .header-chat {
    background-color: mat.get-color-from-palette($background, 'card');
    border-bottom: mat.get-color-from-palette($background, 'divider');

    .case-info-header .provider-id {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .header-options .menu-button {
      background-color: mat.get-color-from-palette(
        $background,
        'secondary-background'
      );
    }
  }
}
