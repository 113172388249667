@use '@angular/material' as mat;
@mixin organization-templates-theme($theme) {
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );

  .view-toggle {
    .mat-button-toggle {
      background-color: mat.get-color-from-palette($primary, 100);
      color: mat.get-contrast-color-from-palette($primary, 100);
    }
    .mat-button-toggle-checked {
      background-color: mat.get-color-from-palette($primary, 500);
      color: mat.get-contrast-color-from-palette($primary, 500);
    }
  }
}
