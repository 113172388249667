@use '@angular/material' as mat;
@mixin case-status-pill-theme($theme) {
  $accent: map-get($theme, 'accent');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');

  case-status-pill span {
    color: mat.get-contrast-color-from-palette($primary, 500);

    &.attending {
      background-color: mat.get-color-from-palette($foreground, 'success');
    }

    &.discarded {
      background-color: mat.get-color-from-palette($foreground, 'disabled');
    }

    &.idle {
      background-color: mat.get-color-from-palette($accent, 600);
    }

    &.resolved {
      background-color: mat.get-color-from-palette($foreground, 'disabled');
    }

    &.waiting {
      background-color: mat.get-color-from-palette($primary, 500);
    }
  }
}
