@import 'ht-typography.scss';
@import 'ht-variables.scss';

.ht-form {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.ht-form-section {
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: relative;
  > button[type='submit'] {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.ht-form-section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: $spacing-ml;
  .ht-form-section-header-title {
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;
    @extend .text-body-big;
    @extend .bold;

    .ht-form-section-header-subtitle {
      @extend .text-small;
      color: $N500;
    }
  }
}

.ht-form-actions {
  display: flex;
  flex-direction: column;
  gap: $spacing-m;
  margin-top: $spacing-xs;
}

.ht-form-row {
  display: flex;
  gap: $spacing-m;
}

.ht-form-item {
  display: flex;
  gap: 2px;
  flex-direction: column;

  &.--inline {
    flex-direction: row;
    align-items: center;
    gap: $spacing-xs;
  }

  .ht-form-item-title {
    @extend .text-body-small;
    @extend .bold;
    color: $N900;

    &.--required::after {
      content: ' *';
      @extend .text-small;
      color: $R600;
      vertical-align: text-top;
    }

    &.--optional::after {
      content: ' (Optional)';
      @extend .text-small-extra;
      color: $N500;
      vertical-align: text-top;
    }
  }

  .ht-form-item-helper-text {
    @extend .text-small;
    color: $N500;
    margin-top: 2px !important;
  }

  &.disabled {
    .ht-form-item-title {
      opacity: 0.7;
    }
  }
}

.ht-form-item-control {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
  min-height: 45px;
  &.--small {
    min-height: 37px;
  }
  &.--tiny {
    min-height: 26px;
  }
}

.ht-form-small {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;

  .ht-form-item {
    display: flex;
    flex-direction: column;
  }
}

.ht-form-table {
  border-spacing: 0;

  tr > :is(th, td) {
    padding: $spacing-xs $spacing-sm;
    &:first-child {
      padding-left: $spacing-ml;
    }
    &:last-child {
      padding-right: $spacing-ml;
    }
  }

  thead {
    background-color: $N50;
    tr {
      th {
        text-align: left;
        padding: $spacing-xs $spacing-sm;
        @extend .text-small;
        color: $N500;
      }
    }
  }

  tbody {
    tr {
      td {
        padding-top: $spacing-ml;
        padding-bottom: $spacing-ml;
        border-bottom: 1px solid $N100;
        vertical-align: top;
      }
    }
  }
}
.ht-form-table--compact {
  thead tr th {
    padding-top: $spacing-xxs;
    padding-bottom: $spacing-xxs;
  }
  tbody tr td {
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xs;
  }
}

ht-modal .ht-form-table {
  width: 100%;
}
dl dd .ht-form-table tr:last-child td {
  border: 0;
}

.error-message {
  @extend .text-small;
  color: $N900 default;
  margin-top: $spacing-xxs !important;
  color: $R600;
  &:first-letter {
    text-transform: uppercase;
  }
  a {
    color: $L500;
  }
}

.error-alert {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: $spacing-xs;
  @extend .text-body-small;
  color: $R600;
  background: $R50;
  padding: $spacing-sm $spacing-ml;
  border-radius: $radius-sm;
  i {
    font-size: 16px;
    margin-top: 3px;
  }
  a {
    color: $R900;
  }
}

.help-text {
  @extend .text-small;
  margin-top: $spacing-xxs !important;
  margin-bottom: 0;
  color: $N500;
  a {
    color: $L500;
  }
}

a.help-text,
.help-text a {
  &:hover {
    text-decoration: underline;
  }
}
