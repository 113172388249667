@use '@angular/material' as mat;
@mixin feedback-theme($theme) {
  $foreground: map-get($theme, 'foreground');
  $warn: map-get($theme, 'warn');

  .ht-notification {
    .error {
      color: mat.get-color-from-palette($warn, 'A400');
    }

    .success {
      color: mat.get-color-from-palette($foreground, 'light-success');
    }
  }
}
