@use '@angular/material' as mat;
@mixin template-preview-theme($theme) {
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );
  $primary: map-get($theme, 'primary');

  .message-container {
    background: mat.get-color-from-palette($background, 'secondary-background');

    .chat-bubble {
      color: mat.get-color-from-palette($foreground, 'text');
      background-color: mat.get-color-from-palette($background, 'card');

      &:after {
        border-right-color: mat.get-color-from-palette($background, 'card');
      }

      p {
        color: mat.get-color-from-palette($foreground, 'text');
      }
    }

    .edit-text p {
      color: mat.get-color-from-palette($foreground, 'disabled-text');
    }

    .template-buttons mat-grid-tile {
      color: mat.get-color-from-palette($foreground, 'text');
      background-color: mat.get-color-from-palette(
        $background,
        'raised-button'
      );
    }

    .title {
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }

  .tags-dropdown {
    background-color: mat.get-color-from-palette($primary, 500);
    color: mat.get-contrast-color-from-palette($primary, 500);
    scrollbar-color: mat.get-color-from-palette($primary, 300)
      mat.get-color-from-palette($primary, 500);

    & > div:hover {
      background-color: darken(mat.get-color-from-palette($primary, 500), 10%);
    }
  }

  .variables-container {
    background: mat.get-color-from-palette($background, 'card');
  }

  .variables-form {
    .choose-media > span {
      color: mat.get-color-from-palette($background, 'status-bar');
    }

    .media-container,
    .media-info {
      background-color: mat.get-color-from-palette($foreground, 'disabled');
      color: mat.get-color-from-palette($background, 'status-bar');
    }
  }
}
