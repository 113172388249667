@import 'ht-variables';
@import 'ht-typography';

$wrapper-spacing: 16px;

.sticky-modal {
  padding: $wrapper-spacing;
  background: white;
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  border-radius: $radius-xs;
  position: absolute;
  z-index: 30;
  margin: $wrapper-spacing;
  right: 0px;
  left: 0px;
  box-shadow:
    0px 6px 18px 0px #424a531f,
    0px 6px 12px -3px #424a530a,
    0px 0px 0px 1px #ffffff00;

  .close-modal {
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: $wrapper-spacing;
  }

  .highlight {
    @extend .text-body-small;
    @extend .bold;

    background: linear-gradient(90deg, #6e49ff 0%, #ff426f 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;

    i {
      margin-right: 5px;
    }
  }

  .title-modal {
    @extend .bold;
    @extend .text-body;
  }

  .highlight,
  .title-modal,
  .text-modal {
    // giving space for close button X
    margin-right: $spacing-ml !important;
  }

  .text-modal {
    @extend .text-body-small;
  }

  .title-modal + .text-modal,
  .highlight + .text-modal {
    // if title/highlight is present, text-modal does not need to give margin
    margin-right: 0px !important;
  }

  .save-modal {
    align-self: flex-end;

    & ::ng-deep button {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
