//REFACTORED
$main-blue: #4a90e2;
$background-logo-blue: #3c7ecc;
$darkblue: #394b5d;
$lightgray: #f2f2f2;
$darkgray: #383838;
$overdarkgray: #cccccc;
$secondary-blue: #6797c8;
$gray: #5c5c5c;
$text-gray: #4a4a4a;
$timebar-green: #50e3c2;
$red: #f3353b;
$darkorange: #f39700;
$lightblue: #d6e0ea;
$darkgreen: #1cb18f; // may be changed to #33972d pending albert approval
$white: #ffffff;
$black: #000000;
$translucent-white: rgba(255, 255, 255, 0.6);
$off-white: #fafafa;
$selected-lightblue: #e6f1ff;
$box-shadow-black: rgba(0, 0, 0, 0.2);

//Status colors
$status-online: #6dcd39;
$status-away: #f5a623;
$status-busy: #ec4343;
$status-offline: #666666;
$status-connecting: #ffffff;

//Feedback colors
$feedback-green: green;
$feedback-red: #b22114;

//Button
$btn-border-color: #d0d0d0;
$btn-border-width: 1px;
$btn-border: solid $btn-border-width $btn-border-color;
$btn-padding: $spacing-xs $spacing-ml;

.snack-bar-color {
  color: red !important;
  button {
    color: #f39700 !important;
  }
}
