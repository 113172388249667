@use '@angular/material' as mat;
@import 'ht-variables';
@mixin progress-bar-theme($theme) {
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $warn: map-get(
    $map: $theme,
    $key: 'warn',
  );
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  .progress-bar-inner {
    background-color: mat.get-color-from-palette($background, 'status-bar');
    .progress-bar {
      background-color: $L500;

      &.active {
        background-color: $L500;
      }

      &.full {
        background-color: $feedback-red;
      }

      &.warn {
        background-color: #dd8b18;
      }

      &.paused {
        // TODO: Can we have this gray as background?
        background-color: mat.get-color-from-palette($foreground, 'hint-text');
      }
    }
  }

  .progress-values {
    color: mat.get-color-from-palette($foreground, 'secondary-text');
  }
}
