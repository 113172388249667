/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'ht-variables';

//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

//@import '@angular/material/theming';
@import './scheme-colors';
@import './app/shared/variables';
@import './style-app-variables.scss';
@import './app/utils/layout.scss';
@import './app/utils/text.scss';
@import './themes/hub-type.scss';

body,
html,
app-root {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

router-outlet + * {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.app-case {
  container-type: inline-size;
  container-name: appcase;
}

// Layout

@include col-span-classes();

.emoji-picker {
  z-index: 40;
}

.hidden {
  visibility: hidden;
}

.m-bottom-1 {
  margin-bottom: $spacing-xxs;
}

// Text

.centered {
  text-align: center;
}

.text-right {
  text-align: right;
}

// App Styles

a.plain {
  cursor: pointer;
  text-decoration: none;

  &.plain-upper {
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: underline;
  }
}

.main {
  width: fit-content;

  & > section {
    display: grid;
    grid-template-columns: repeat(12, minmax(70px, 84px));
    gap: $spacing-sm;
  }

  h2 {
    font-weight: 700;
  }
}

.ht-content {
  display: flex;
  flex-direction: column;
  gap: $spacing-ml;
  padding-bottom: $spacing-ml;
}

.empty-data {
  color: $darkgray;
  font-size: 18px;
  font-weight: 300;
  margin-top: 2em;
  text-align: center;

  mat-icon {
    font-size: 60px;
    height: 70px;
    width: 70px;
  }
}

.filter-menu {
  @include app-font-family();
  font-size: 14px;
  font-weight: 400;

  .grouped {
    font-size: 14px;
    font-weight: 400;
    margin: $spacing-xs 0;
    padding: 0 15px;
  }

  .filter-menu-item {
    padding-right: $spacing-s;

    .mat-checkbox-label {
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }

  .mat-menu-item {
    height: 36px;
    line-height: 36px;
  }
}

.filter-menu mat-checkbox > label {
  display: block;
  padding: 6px 0;

  & > span {
    vertical-align: middle;
  }
}

// Angular Material Overrides

// See http://www.talkingdotnet.com/dont-use-hidden-attribute-angularjs-2/
[hidden] {
  display: none !important;
}

.mat-menu-panel {
  margin-top: -10px;

  &.filter-menu {
    min-width: 188px !important;
  }
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Lato',
);
@include mat.all-legacy-component-typographies($custom-typography);

.mat-calendar-body-label {
  visibility: hidden;
}

.mat-card {
  box-shadow: none !important;
}

.mat-dialog-container {
  padding: 0px !important;
  min-width: 400px;

  .mat-typography {
    width: 100%;
  }

  .dialog-header {
    padding: $spacing-s $spacing-ml;
    width: 100%;

    h3 {
      font-size: 18px;
      margin: 0px !important;
    }

    .close-icon {
      cursor: pointer;
    }
  }

  .dialog-body {
    @include app-font-family();
    padding: $spacing-ml;
    overflow-y: auto;
  }

  .dialog-body .warning-text {
    line-height: 21.6px;
    margin-bottom: 10px;
  }

  .dialog-footer {
    padding: $spacing-sm $spacing-m 26px;
    button {
      margin-left: $spacing-s;
    }
  }
}

mat-list.list-striped {
  mat-list-item {
    h4 {
      font-weight: bold;
      font-size: 16px;
    }

    span.item-edit-btn {
      padding-left: 5px;
      font-weight: 700;
      font-size: 12px;
    }
  }
}
