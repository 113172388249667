@use '@angular/material' as mat;
@mixin hubtype-layout-theme($theme) {
}
@import 'ht-variables';

.right-side {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  padding: $spacing-s $spacing-ml;
  flex-direction: column;
  overflow: auto;
  outline: none;
}

::ng-deep {
  .ht-tab {
    margin-left: (-$spacing-ml);
    margin-right: (-$spacing-ml);
    margin-bottom: $spacing-ml;
  }
}

.icon-info-tooltip {
  font-size: 10px;
  color: $N300;
  vertical-align: middle;
}
