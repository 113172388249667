@use '@angular/material' as mat;
@mixin case-transfer-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  .agent-contact {
    color: mat.get-color-from-palette($foreground, 'secondary-text');
  }

  .agent-list {
    mat-list-option + mat-list-option {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
      border-bottom: 1px solid
        mat.get-color-from-palette($foreground, 'divider');
    }

    mat-list-option:first-child {
      border-top: 1px solid mat.get-color-from-palette($foreground, 'divider');
    }
  }

  .queue-avatar > mat-icon {
    background-color: mat.get-color-from-palette(
      $background,
      'secondary-background'
    );
  }

  .tag {
    background-color: mat.get-color-from-palette($background, 'background');
    color: mat.get-color-from-palette($foreground, 'text-cta');
  }
}
