@use '@angular/material' as mat;
@mixin tag-filter-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );
  $primary: map-get($theme, 'primary');

  .contacts-meta > div {
    &:last-child {
      color: mat.get-color-from-palette($foreground, 'hint-text');
    }
    &:first-child {
      color: unset;
    }
  }

  .tag-container {
    input {
      background-color: transparent;
      color: mat.get-contrast-color-from-palette($primary, 500);
      outline: 0;

      &::placeholder {
        color: mat.get-contrast-color-from-palette($primary, 500);
        opacity: 0.5;
      }
    }
  }

  .tags-autocomplete.valued .tag-no-value mat-chip {
    background-color: mat.get-color-from-palette(
      $foreground,
      'secondary-text'
    ) !important;
  }
}
