@use '@angular/material' as mat;
@mixin tree-theme($theme) {
  $primary: map-get(
    $map: $theme,
    $key: 'primary',
  );
  $background: map-get(
    $map: $theme,
    $key: 'background',
  );
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  .folder-crud {
    color: mat.get-color-from-palette($foreground, 'lighten-text');
  }

  .selectable-node {
    color: mat.get-color-from-palette($foreground, 'lighten-text');

    &.selected {
      color: mat.get-color-from-palette($primary, 500);
    }
  }

  .mat-tree-node {
    &:hover {
      background-color: mat.get-color-from-palette($background, 'hover');
    }
  }

  .add-folder-button {
    &:hover {
      background-color: mat.get-color-from-palette(
        $background,
        'secondary-background'
      );
    }
  }
}
