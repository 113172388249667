@use '@angular/material' as mat;
@mixin case-chat-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');

  app-case-chat {
    background-color: mat.get-color-from-palette(
      $background,
      'secondary-background'
    );

    mat-progress-spinner {
      color: mat.get-color-from-palette($primary, 500);
    }

    .chat-end {
      color: mat.get-color-from-palette($foreground, 'secondary-text');
    }

    .mat-toolbar {
      background: mat.get-color-from-palette($background, 'card');
      color: mat.get-color-from-palette($foreground, 'lighten-text');
    }
  }
}
