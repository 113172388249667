@use '@angular/material' as mat;
@mixin contact-counter-theme($theme) {
  $foreground: map-get(
    $map: $theme,
    $key: 'foreground',
  );

  .contacts-meta > div {
    &:last-child {
      color: mat.get-color-from-palette($foreground, 'hint-text');
    }
    &:first-child {
      color: unset;
    }
  }
}
