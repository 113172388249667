@use '@angular/material' as mat;
@mixin selector-theme($theme) {
  $foreground: map-get($theme, 'foreground');

  app-selector .ht-selector {
    mat-form-field {
      border: 1px solid mat.get-color-from-palette($foreground, 'dividers');
    }

    mat-label {
      color: mat.get-color-from-palette($foreground, 'text');
    }
  }
}
