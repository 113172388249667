@use '@angular/material' as mat;
@mixin list-message-theme($theme) {
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');
  $primary: map-get($theme, 'primary');

  .list-message {
    .element {
      border-color: mat.get-color-from-palette($background, 'focused-button');

      .button {
        border-top-color: mat.get-color-from-palette(
          $background,
          'focused-button'
        );
        color: mat.get-color-from-palette($primary, 300);
      }

      .element-subtitle {
        color: mat.get-color-from-palette($foreground, 'lighten-text');
      }
    }
  }
}
